import * as React from "react";
import { cls } from "@utdanningsdirektoratet/utils/cls";
import { capitalize } from "@utdanningsdirektoratet/utils/string";
import styles from "./Box.module.less";

type BoxProps = {
  color: "palegreen" | "darkgreen" | "black" | "white" | "whitepalegreen" | "whitedarkgreen";
  noBorder?: boolean;
  medium?: boolean;
  children?: React.ReactNode;
};

const Box: React.FC<BoxProps> = ({ color, noBorder, medium, children }) => {
  const boxClass = cls({
    [styles.box]: true,
    [styles.boxMedium]: medium,
    [styles.boxNoBorder]: noBorder,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    [styles[`box${capitalize(color)}`]]: true,
  });
  return <div className={boxClass}>{children}</div>;
};

export default Box;
