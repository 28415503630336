import * as React from "react";
import { cls } from "@utdanningsdirektoratet/utils/cls";
import styles from "./Header.module.less";

type HeaderProps = {
  mailto: string;
};

const Header: React.FC<HeaderProps> = ({ mailto }) => {
  const [hasScrolled, setHasScrolled] = React.useState(false);

  React.useEffect(() => {
    const onScroll = () => {
      setHasScrolled(window.pageYOffset > 0);
    };
    onScroll();
    document.addEventListener("scroll", onScroll);

    return () => {
      document.removeEventListener("scroll", onScroll);
    };
  }, []);

  const headerClass = cls({
    [styles.header]: true,
    [styles.headerWithBackground]: hasScrolled,
  });

  return (
    <div className={headerClass}>
      <div className={styles.headerContent}>
        <div className={styles.headerTitle}>Høring</div>
        <a className={styles.headerLink} href={mailto} target="_blank" rel="noopener noreferrer">
          Kontakt oss
        </a>
      </div>
    </div>
  );
};

export default Header;
