import * as React from "react";
import styles from "./Footer.module.less";

const Footer: React.FC = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.footerContent}>
        <p>Høringssystemet eies og utvikles av Utdanningsdirektoratet.</p>
        <p>
          <a className={styles.footerLink} href="https://www.udir.no/om-udir/personvernerklaring-udir/">
            Personvern og informasjonskapsler
          </a>{" "}
          <a
            className={styles.footerLink}
            href="https://uustatus.no/nb/erklaringer/publisert/ce43e104-3893-45ac-90c8-45deb6f17624"
          >
            Tilgjengelighetserklæring
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
