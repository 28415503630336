import "./index.less";
import { createRoot } from "react-dom/client";
import { ParallaxProvider } from "react-scroll-parallax";

import App from "./App";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
require("intersection-observer");

const container = document.getElementById("app");
const root = createRoot(container!);
root.render(
  <ParallaxProvider>
    <App />
  </ParallaxProvider>
);
