import * as React from "react";
import { cls } from "@utdanningsdirektoratet/utils/cls";
import { capitalize } from "@utdanningsdirektoratet/utils/string";
import styles from "./Slice.module.less";

type SliceProps = {
  color?: "palegreen" | "darkgreen";
  className?: string;
  children?: React.ReactNode;
};

const Slice: React.FC<SliceProps> = ({ color, className, children }) => {
  const sliceClass = cls(
    {
      [styles.slice]: true,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [styles[`slice${capitalize(color)}`]]: color,
    },
    className
  );

  return (
    <article className={sliceClass}>
      <div className={styles.sliceContent}>{children}</div>
    </article>
  );
};

export default Slice;
