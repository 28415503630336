import * as React from "react";

type ImageProps = {
  image: string;
  width?: string;
  height?: string;
  alt?: string;
  className?: string;
  style?: React.CSSProperties;
};

const Image: React.FC<ImageProps> = ({ image, width, height, alt, className, style }) => {
  return <img className={className} src={image} width={width} height={height} alt={alt} style={style} />;
};

export default Image;
