import * as React from "react";
import { cls } from "@utdanningsdirektoratet/utils/cls";

import udir from "images/udir-min.png";
import dsb from "images/dsb-min.png";
import miljodirektoratet from "images/miljodirektoratet-min.png";
import forskningsradet from "images/forskningsradet-min.png";
import dibk from "images/dibk-min.png";
import mattilsynet from "images/mattilsynet-min.png";
import arbeidstilsynet from "images/arbeidstilsynet-min.png";
import Image from "../Image";
import styles from "./Customers.module.less";

type CustomersProps = {
  className?: string;
};

const Customers: React.FC<CustomersProps> = ({ className }) => {
  const customerClass = cls(
    {
      [styles.customers]: true,
    },
    className
  );

  return (
    <div className={customerClass}>
      <a href="https://www.udir.no/om-udir/hoyringar/" target="_blank" rel="noopener noreferrer">
        <Image className={styles.customersLogo} image={udir} alt="Utdanningsdirektoratet logo" />
      </a>
      <a href="https://www.dsb.no/hoeringer-og-konsekvensutredninger/" target="_blank" rel="noopener noreferrer">
        <Image className={styles.customersLogo} image={dsb} alt="DSB logo" />
      </a>
      <a href="https://www.miljodirektoratet.no/hoeringer/" target="_blank" rel="noopener noreferrer">
        <Image className={styles.customersLogo} image={miljodirektoratet} alt="Miljødirektoratet logo" />
      </a>
      <a href="https://www.forskningsradet.no/" target="_blank" rel="noopener noreferrer">
        <Image className={styles.customersLogo} image={forskningsradet} alt="Forskningsrådet logo" />
      </a>
      <a href="https://www.dibk.no/" target="_blank" rel="noopener noreferrer">
        <Image className={styles.customersLogo} style={{ maxHeight: "100px" }} image={dibk} alt="Direktoratet for byggkvalitet logo" />
      </a>
      <a href="https://www.mattilsynet.no/" target="_blank" rel="noopener noreferrer">
        <Image className={styles.customersLogo} image={mattilsynet} alt="Mattilsynet logo" />
      </a>
      <a href="https://www.arbeidstilsynet.no/" target="_blank" rel="noopener noreferrer">
        <Image className={styles.customersLogo} image={arbeidstilsynet} alt="Arbeidstilsynet logo" />
      </a>
    </div>
  );
};

export default Customers;
